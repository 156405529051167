/*
 * @Description: 
 * @Version: 1.0
 * @Autor: ziwei
 * @Date: 2021-08-16 17:39:04
 * @LastEditors: ziwei
 * @LastEditTime: 2021-08-19 14:22:17
 */
import { Technical,Com }  from '@/api/index'
import { dealUrlHttp } from '@/utils/com';
import { Home } from '@/api/index'

//获取左侧的广告
export async function leftAdv(data){
    let res = await Home.advertPicture(data);
    if(res&&Array.isArray(res)){
      res.forEach((v)=>{
        v.url = dealUrlHttp(v.url)
      })
      return res||[];
    }
}

//头部轮播
export async function rotationChart(data){
    let res = await Technical.rotationChart(data)
    if(res&&Array.isArray(res)){
      return res.map((v)=>{
        return {
          url:dealUrlHttp(v.url),
        }
      })
    }
}

//文档列表
export async function docInfo(data){
    let res =await Technical.newInfo(data)
    return res;
}

//文档详情
export async function docDetail(data){
  let res =await Com.newsDetail(data)
  return res;
}

export async function productSort(data){
    let res = await Technical.productSort(data)
    return res;
}

export async function getProductList(data){
  let res = await Technical.getProductList(data)
  return res
}