<!--
 * @Description: 技术服务文档列表
 * @Version: 1.0
 * @Autor: ziwei
 * @Date: 2021-08-09 16:05:57
 * @LastEditors: ziwei
 * @LastEditTime: 2021-08-19 16:33:58
-->
<template>
  <div class="team-services">
    <ul class="w">
      <li v-for="(item, index) in productList" :key="index">
        <div class="team-services-item">
          <div class="team-services-item-img">
            <a  @contextmenu="openNewWindow($event,item.owid,item.exp2)"><img :src="pictrueUrl + item.productPic" alt="" @click="goDetailPage(item.owid,item.exp2)"/></a>
          </div>
        </div>
        <div class="content-productName">
          <div class="productName">{{item.productName}}</div>
          <div class="team-services-line"></div>
          <div class="team-services-item">{{item.secondName}}</div>
        </div>
      </li>
      <div class="clear"></div>
    </ul>
    <div class="clear"></div>
    <!-- <div class="w ww jishu_con">
      <div class="pc_fr kt_list_rt">
        <ul class="zx_c">
          <li class="thisclass">
                    <div class="w card-view_jishu">
                      <div class="card-view_1_jishu" v-for="(item, index) in productList" :key="index">
                        <a  @contextmenu="openNewWindow($event,item.owid,item.exp2)"><img :src="pictrueUrl + item.productPic" alt="" @click="goDetailPage(item.owid,item.exp2)"/></a>
                        <div class="content">
                          <span>{{item.productName}}</span>
                          <p>{{ item.secondName}}</p>
                        </div>
                      </div>
                    </div>
              <div class="clear"></div>
            <Pagination
              :totalPage="totalPage"
              :currentPage="currentPage"
              @pageFun="goPageSrh"
            />
          </li>
        </ul>
      </div>
      <div class="clear"></div>
    </div> -->

  </div>
</template>

<script>
import { CODE,showLayer, hlLeftMenu } from "@/utils/com";
import { docInfo, leftAdv, getProductList } from "./model";
import { mapState, mapActions } from "vuex";
import Pagination from "@/components/component/Pagination.vue";
import { IMGURL } from "@/utils/constant";
export default {
  data() {
    return {
      pictrueUrl: "",
      banner: "",
      thirdVal: "",
      //快捷搜索
      tagSrh: {
        time: 2, //降序
        view: "", //浏览
        down: "", //下载
      },
      categoryRefOwid: "",
      productList: "",
      totalPage: 1,
      currentPage: 1,
      // 保存数据
      saveData: "",
      thirdVal: "", //三级查询值

      leftAdvData: [], //左侧广告
    };
  },
  async created() {
    this.pictrueUrl = IMGURL;
    // rotationChart({ lx: CODE.TECHCENTER }).then((res) => {
    //   this.banner = res;
    // });
    getProductList({ categoryRefOwid: 87 }).then((res) => {
      this.productList = res.records;
    });
    this.getDocs();

    let res = await leftAdv({ lx: "001" });
    this.leftAdvData = res;
  },
  computed: {
    ...mapState("product", ["totalCount"]),
    ...mapState("common", ["leftMenu"]),
    getMenuLeft() {
      //获取页面左侧菜单
      return hlLeftMenu(this.leftMenu);
    },
  },
  methods: {
    ...mapActions("product", ["getProduct", "takeClt", "cancelClct"]),
    //头部三级搜索
    thirdSrh(e) {
      let val = e.target.dataset.id;
      this.thirdVal == val ? (this.thirdVal = "") : (this.thirdVal = val);
      this.$emit("searchDoc", this.thirdVal);
      getProductList({ categoryRefOwid: this.thirdVal || 87 }).then((res) => {
        this.productList = res.records;
      });
    },
    //获取文档列表
    async getDocs(pageNo = 1) {
      let { time, view, down } = this.tagSrh;
      let res = await docInfo({
        zxlb: "002",
        pageNo: pageNo,
        fbsj: time,
        ydcs: view,
        downNum: down,
        pageSize: 12,
      });
      if (res) {
        this.saveData = res.records || [];
        this.totalPage = res.totalPage || 1;
        this.currentPage = res.currentPage;
      }
    },

    //tag 快捷搜索
    setTagSrh(type, e) {
      let { time, view, down } = this.tagSrh;
      if (type == "time") {
        time ? (this.tagSrh.time = "") : (this.tagSrh.time = 2);
      }
      if (type == "view") {
        view ? (this.tagSrh.view = "") : (this.tagSrh.view = 2);
      }
      if (type == "down") {
        down ? (this.tagSrh.down = "") : (this.tagSrh.down = 2);
      }
      this.getDocs();
    },

    openNewWindow(e,owid,exp2){
      e.path[1].href = ''
      e.path[1].href = e.path[1].href+'/#/productdetails/?proId='+owid+"&"+'exp2='+exp2;
    },
    goDetailPage(owid,exp2){
      this.$router.push({path:'/productdetails',query:{proId:owid,exp2:exp2}})
    },
    //分页搜索
    goPageSrh(pageNo) {
      this.getDocs(pageNo);
    },
    // //收藏
    async collect(id, isCollection, index) {
      if (isCollection == 1) {
        //已經收藏取消收藏
        let result = await this.cancelClct({ collectList: id, index });
        result && showLayer("toast", this.$t("calCollect"));
      } else {
        //收藏
        let result = await this.takeClt({ collectList: id, index });
        result && showLayer("toast", this.$t("clectSuccess"));
      }
    },
  },
 
};
</script>

