<!--
 * @Description: 
 * @Version: 1.0
 * @Autor: ziwei
 * @Date: 2021-08-09 16:05:57
 * @LastEditors: ziwei
 * @LastEditTime: 2021-08-18 18:23:36
-->
<template>
  <div class="technology">
    <!-- <banner @searchDoc="searchDoc" /> -->
    <team-service ref="docDetail"/>
  </div>
</template>

<script>
// import Banner from './Banner.vue'
import TeamService from './TeamService.vue'
export default {
  data () {
    return {
    }
  },

  components: {
    // Banner,
    TeamService,
  },
  
  // methods:{
  //   searchDoc(thirdVal){
  //     this.$refs.docDetail.thirdVal=thirdVal;
  //     this.$refs.docDetail.getDocs();
  //   }
  // }
}
</script>

<style>
</style>